<template>
  <v-app class="bg-image">
    <router-view />
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  font-family: "Montserrat", "Poppins", sans-serif;
  /* color: #4d4d4e; */
}

.text-title {
  font-weight: 600;
  font-size: 2em;
}

.text-list-regular {
  font-weight: 400;
  font-size: 1.3em;
}

/* .bg-image {
  background-image: url("../public/img/Fondo Interno.svg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
} */
</style>
